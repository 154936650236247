@import '../../styles/mixins';

$largest-size: 1440px;
$large-size: 1280px;
$medium-size: 1024px;
$small-size: 768px;
$small-mid-size: 667px;
$smallest-size: 480px;
$ultra-smallest-size: 375px;

$transition-slow: 450ms ease;
$transition-base: 300ms ease;

$font-size-base: 16;
$font-family-base: 'Open Sans', sans-serif;
$font-family-solid: 'Montserrat', sans-serif;
$font-family-italic: 'Libre Baskerville', sans-serif;

$color-blue: #006CA9;
$color-orange: $color-blue;
$color-orange-dark: $color-blue;
$color-orange-1: #FF6B58;
//$color-orange-dark: #c94a24;

$color-laguna: #00A3AF;
$color-laguna-light: #F2FAFB;
$color-laguna-dark: #007178;
//$color-laguna-dark: #00ADB5;

$color-grey-light: #adadad;
$color-grey: #676767;

$primary-1: #ae0032;
$primary-2: #455162;
$primary-3: #1f2f46;

$neutral-1: #0d1720;
$neutral-2: #636363;
$neutral-3: #dadada;
$neutral-4: #f2f2f2;
$neutral-5: #fcfbfa;

.blog-posts {
    margin-bottom: 100px;
}

.blog-posts__container {
    padding-top: 0;
    padding-bottom: 100px;

    &_with-top-padding {
        padding-top: 100px;
    
        @media (min-width: $medium-size) {
            padding-top: 150px;
        }
    }
    
    @media (min-width: $medium-size) {
        padding-bottom: 150px;
    }
}
.blog-posts__heading {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    margin-bottom: 40px;

    @media (min-width: $smallest-size) {
        flex-direction: row;
    }
}
.blog-posts__heading-italic {
    display: inline-block;

    @media (min-width: $smallest-size) {
        margin-left: 8px;
    }
    @media (min-width: $small-size) {
        margin-left: 12px;
    }
}
.blog-posts__filters {
    margin-bottom: 40px;

    position: sticky;
    top: var(--header-height);
    left: 0;
    right: 0;
    z-index: 10;

    background-color: #ffffff;
    box-shadow: 0px 1px 4px rgba(56, 56, 56, 0.15);

    @media (min-width: $small-size) {
        position: static;
        top: auto;
        left: auto;
        right: auto;

        box-shadow: none;
    }
}
.blog-posts__expand-btn {
    width: 100%;
    padding: 15px 24px;

    position: relative;

    background-color: #ffffff;
    transition: 300ms ease-in-out;

    @media (min-width: $small-size) {
        display: none;
    }

    &::after {
        content: '';

        width: 9px;
        height: 9px;
        border-top: 1px solid #222;
        border-right: 1px solid #222;

        position: absolute;
        top: 45%;
        right: 24px;
        transform: translateY(-50%) rotate(135deg);
        
        transition: 300ms ease-in-out;
    }

    &.opened {
        color: #ffffff;

        background-color: $color-laguna;

        &::after {
            border-top-color: #ffffff;
            border-right-color: #ffffff;

            top: 55%;
            transform: translateY(-50%) rotate(-45deg);
        }
    }
}
.blog-posts__filter-list {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    display: none;

    max-height: 70vh;
    padding: 15px 20px;

    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    overflow-y: auto;

    background-color: #ffffff;
    box-shadow: 0px 1px 4px rgba(56, 56, 56, 0.15);

    &.opened {
        display: flex;
    }

    @media (min-width: $smallest-size) {
        flex-direction: row;
    }
    @media (min-width: $small-size) {
        display: flex;

        max-height: none;
        padding: 0;
        
        position: static;

        box-shadow: none;
    }
}
.blog-posts__filter-item {
    &:not(:last-child) {
        margin-right: 10px;
        margin-bottom: 8px;
    }
    label {
        padding: 12px 16px;

        @include btn__style-hollow-orange;

        cursor: pointer;

        @media (min-width: $small-size) {
            padding: 15px 25px;
        }
    }
    input {
        position: absolute;
        clip: rect(0 0 0 0);
        width: 1px;
        height: 1px;
        margin: -1px;
        overflow: hidden;
        -webkit-appearance: auto;

        &.checked + label,
        &:checked + label {
            background-color: $color-orange-dark;
            color: #ffffff;
        }
    }
}
.blog-posts__list {
    display: grid;
    grid-row-gap: 40px;
    grid-column-gap: 24px;

    @media (min-width: $small-mid-size) {
        grid-template-columns: 1fr 1fr;
    }
    @media (min-width: $medium-size) {
        grid-template-columns: 1fr 1fr 1fr;
    }
    @media (min-width: $large-size) {
        grid-column-gap: 32px;
    }
}
.blog-posts__wr-btn {
    display: flex;
    justify-content: center;

    margin-top: 40px;
}
.blog-posts__btn {
    width: 100%;
    padding: 12px 32px;

    text-align: center;

    @include btn__color-orange-dark;

    cursor: pointer;

    @media (min-width: $smallest-size) {
        width: auto;
        padding: 15px 32px;
    }
}